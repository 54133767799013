import React, { useState } from "react";
import project1 from '../Images/OurProject/SsfSavor.1f5fb754.png'
import project2 from '../Images/OurProject/Cab5.b7ed8f1d.png'
import project3 from '../Images/OurProject/FoodChannel.7285e425.png'
import project4 from '../Images/OurProject/Linkefy.0f4eb2d6.png'
import project5 from '../Images/OurProject/QuickWays.2f671178.png'
import project6 from '../Images/OurProject/Vheelo.258e66b7.png'
import project7 from '../Images/OurProject/RooiBoos.f622512b.png'
import project8 from '../Images/OurProject/website/4thGeneration.57249ba4.png'
import project9 from '../Images/OurProject/website/AestheticaPlastika.1980798e.png'
import project10 from '../Images/OurProject/website/Bch.8a4ce993.png'
import project11 from '../Images/OurProject/website/BlackLabelCannabis.78f36887.png'
import project12 from '../Images/OurProject/website/ChampionshipBelts.fe9b71d9.png'
import project13 from '../Images/OurProject/website/Fitthenics.be5c5ff8.png'
import project14 from '../Images/OurProject/website/HairFree.405916f2.png'
import project15 from '../Images/OurProject/website/Illc.4c405dc8.png'
import project16 from '../Images/OurProject/website/IsscOdont.7c9c7a4e.png'
import project17 from '../Images/OurProject/website/KashmirCloth.40f8f6bb.png'
import project18 from '../Images/OurProject/website/PortfolioInDepth.03cbc56f.png'
import project19 from '../Images/OurProject/website/Prp.589999fd.png'
import project20 from '../Images/OurProject/website/Virsa.581aefc6.png'
import project21 from '../Images/OurProject/website/UltimateAdventureTours.e26508da.png'
import project22 from '../Images/OurProject/website/Tks.8dd29ce6.png'
import project38 from '../Images/OurProject/website/Baba Machine Web Development Post.jpg'
import project39 from '../Images/OurProject/website/RIC Web Development Post.jpg'
import project23 from '../Images/OurProject/website/RoyalMate.f852bace.png'
import project33 from '../Images/OurProject/website/Web Development Post.jpg'
import project25 from '../Images/OurProject/Ecom/Artisan.a3e2e9b5.png'
import project26 from '../Images/OurProject/Ecom/AshhKaro.e9ef2943.png'
import project27 from '../Images/OurProject/Ecom/Fashionably.51df3095.png'
import project28 from '../Images/OurProject/Ecom/KokoBazar.2bfe438b.png'
import project29 from '../Images/OurProject/Ecom/Luks.0219f089.png'
import project30 from '../Images/OurProject/Ecom/ShakuGems.a0ee1beb.png'
import project31 from '../Images/OurProject/Ecom/StShop.6dcc1e36.png'
import project24 from '../Images/OurProject/Ecom/VapourSmoke.a943487b.png'
import project43 from '../Images/OurProject/website/world staff system Web Development Post.jpg'
import project53 from '../Images/OurProject/website/HSA Website Development Project Instagram Post.jpg'
import project54 from '../Images/OurProject/website/Blue World City Website Development Services Instagram Post.jpg'
import project56 from '../Images/OurProject/website/Green Food Development Service Instagram Post.jpg'
import project57 from '../Images/OurProject/website/DHA Islamabad Web Development Post.jpg'
import project58 from '../Images/OurProject/website/Duclair Foundation Website Development Post.jpg'
import project59 from '../Images/OurProject/website/Zivaj Mobile App Development Post.jpg'
import project60 from '../Images/OurProject/website/Infinity Soltech Vheelo Mobile App Development Post.jpg'
import project61 from '../Images/OurProject/website/Freedom Pay Universe Web Development Service Post.jpg'

import project55 from '../Images/OurProject/website/Purple and White Modern Website Development Facebook Cover.jpg'
import { Link } from "react-router-dom";
// Sample project data
const projects = [
  { id: 24, name: "VistaMart", category: "Ecommerce Store", image: project33,link:'https://vistamart.biz/' },

  { id: 38, name: "Baba Machine", category: "Websites", image: project38,link:'https://babamachine.lighthouseclouds.com/' },
  

  { id: 39, name: "RIC ", category: "Ecommerce Store", image: project39,link:'https://hsacafe.lighthouseclouds.com/' },
  { id: 43, name: "World Staff System", category: "Websites", image: project43,link:'https://worldstaffsystem.com/' },
 
  { id: 53, name: "Health Service Academy", category: "Websites", image: project53,link:'https://hsa.com.pk/' },
  { id: 54, name: "Blue World City ", category: "Websites", image: project54,link:'https://BlueWord.com/' },
  { id: 55, name: "Cosmic Website", category: "Websites", image: project55 ,link:'https://www.theaestheticsociety.org/'},
  { id: 56, name: "Green Food Development Service", category: "Websites", image: project56 ,link:'https://greenfoods.com.pk/'},
  { id: 57, name: "DHA Islamabad-Rawalpindi", category: "Websites", image: project57 ,link:'https://www.dhai-r.com.pk/'},
  { id: 58, name: "Duclair Foundation", category: "Websites", image: project58 ,link:'https://duclairfoundation.com/'},
  { id: 59, name: "Zivaj Mobile App", category: "Applications", image: project59 ,link:'https://play.google.com/store/apps/details?id=com.zivaj2&hl=en&pli=1'},
  { id: 60, name: "Infinity Soltech Vheelo", category: "Applications", image: project60 ,link:'https://www.infinitysoltech.com/projects/vheelo/'},
  { id: 61, name: "Freedom Pay Universe", category: "Websites", image: project61 ,link:'https://www.freedompayuniverse.org/'},

 { id: 8, name: "4thGeneration", category: "Websites", image: project8,link:'https://4thgenhomes.com/' },
  { id: 9, name: "Aesthetica Plastika", category: "Websites", image: project9 ,link:'https://www.theaestheticsociety.org/'},
  { id: 10, name: "Bch", category: "Websites", image: project10 ,link:'https://bachchristianhospital.org/'},
  { id: 11, name: "BlackLabelCannabis", category: "Websites", image: project11 ,link:'https://blklabelcannabis.com/'},
  { id: 12, name: "ChampionshipBelts", category: "Websites", image: project12,link:'https://www.championsbelts.com/' },
  { id: 13, name: "Fitthenics", category: "Websites", image: project13 ,link:'https://rehbar.pk/biz/garrison-gymnasium/'},
  { id: 14, name: "HairFree", category: "Websites", image: project14 ,link:'https://hairfree.de/'},
  { id: 15, name: "Illc", category: "Websites", image: project15 ,link:'https://berlitz.com.pk/'},
  { id: 16, name: "IsscOdont", category: "Websites", image: project16,link:'https://ascendant.com/' },
  { id: 17, name: "KashmirCloth", category: "Websites", image: project17 ,link:'https://kashmircloth.com.pk/'},
  { id: 18, name: "PortfolioInDepth", category: "Websites", image: project18 ,link:'https://photodune.net/item/lets-discuss-the-portfolio-in-depth/53224621'},
  { id: 19, name: "Prp", category: "Websites", image: project19,link:'https://orthoinfo.aaos.org/en/treatment/platelet-rich-plasma-prp/' },
  { id: 20, name: "Virsa", category: "Websites", image: project20 ,link:'https://virsa.com.pk/'},
  { id: 21, name: "UltimateAdventureTours", category: "Websites", image: project21,link:'https://ultimateadventure.pk/' },
  { id: 22, name: "Tks", category: "Websites", image: project22 ,link:'https://www.tks-construction.com/'},
  { id: 23, name: "RoyalMate", category: "Websites", image: project23 ,link:'https://islamabad.infoisinfo.com.pk/card/royal-mate-properties/151906'},

  { id: 25, name: "VapourSmoke", category: "Ecommerce Store", image: project24 ,link:'https://us.rosco.com/en/product/vapour-fog-machine' },

  { id: 26, name: "Artisan", category: "Ecommerce Store", image: project25 ,link:'https://artisansmokeshop.ca/'},
  
  { id: 27, name: "AshhKaro", category: "Ecommerce Store", image: project26,link:'https://play.google.com/store/apps/details?id=com.cashkaro&hl=en&pli=1' },
  
  { id:28, name: "Fashionably", category: "Ecommerce Store", image: project27,link:'https://www.stkate.edu/newswire/news/fashionably-frugal-save-money-stay-scary' },
  
  { id: 29, name: "KokoBazar", category: "Ecommerce Store", image: project28,link:'https://www.kokosbazaar.com/' },
  
  { id: 30, name: "Luks", category: "Ecommerce Store", image: project29 ,link:'https://luksdecor.com/images/luxury%20Home%20Furniture%20%202.jpg?1731483636414' },
  
  { id: 31, name: "ShakuGems", category: "Ecommerce Store", image: project30 ,link:'https://www.etsy.com/shop/ShakugemsStore'},
  
  { id: 32, name: "StShop", category: "Ecommerce Store", image: project31 ,link:'https://www.stshop.com.bd/'},
  { id: 1, name: "Ssf Savor", category: "Applications", image: project1,link:'https://www.foodfusion.com/' },
  { id: 2, name: "Cab-5", category: "Applications", image: project2 ,link:'https://play.google.com/store/apps/details?id=com.cabe.rider&hl=en'},
  { id: 3, name: "Food Channel", category: "Applications", image: project3,link:'https://food-food.en.aptoide.com/app' },
  { id: 4, name: "Linkefy", category: "Applications", image: project4,link:'https://linkefy.netlify.app' },
  { id: 5, name: "QuickWays", category: "Applications", image: project5 },
  { id: 6, name: "Vheelo", category: "Applications", image: project6 ,link:'https://play.google.com/store/apps/details?id=com.bytes.vheeline&hl=en_AU'},
  { id: 7, name: "Rooi Boos", category: "Applications", image: project7,link:'https://www.rooibostee.shop/en/?srsltid=AfmBOoqZNrOHyyf363uBMvDDpmYgTYA8Foa3cvvNbjGO5mhsJqXwzSv0'},
];

const OurBestProjects = () => {
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [showAll, setShowAll] = useState(false);
  
    const filteredProjects = projects.filter(
      (project) => selectedCategory === "All" || project.category === selectedCategory
    );
  

    const displayedProjects = showAll ? filteredProjects : filteredProjects.slice(0, 6);
  
 

 
  const bg1 = "/";
  const bg2 = "/Home-Herobg-c2e4d53f.png";

  const style = {
    backgroundImage: `
            linear-gradient(rgba(206, 91, 91, 0.4), rgba(144, 240, 231, 0.5)),
            url(${bg2}),
            url(${bg1})`,
    backgroundSize: "contain, cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right, center",
    backgroundColor: "white", // Fallback background color
  };

  return (
    <div className="OurBestProjects py-5" style={style}>
    <div className="container-fluid text-center">
      <h2 className="borderBottom mb-5">
        <span>Our</span> Best <span className="greenColor">Projects</span>
      </h2>
      <div className="projectContainer" >
        {/* Tabs */}
        <ul
          className="nav p-2 d-flex align-items-center justify-content-center nav-tabs"
          id="myTab"
          role="tablist"
          style={{ backgroundColor: "#ffffff", borderRadius: "10px" }}
        >
          {["All","Ecommerce Store", "Websites","Applications" ].map((category) => (
            <li className="nav-item" role="presentation" key={category}>
              <button
                className={`nav-link ${selectedCategory === category ? "active" : ""}`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </button>
            </li>
          ))}
        </ul>

        {/* Projects Grid */}
        <div className="tab-content p-3">
          <div className="row justify-content-center OurBestProject gap-2">
            {displayedProjects.map((project) => (
              <Link to={project.link}
                key={project.id}
                className=" col-lg-3 bestProjectsBoxes col-md-6 col-sm-6 mb-2   rounded-3 bg-transparent p-2"
                style={{ border: "3px solid #903519", color: "white", }}
              >
                <img
                  src={project.image}
                  className="img-fluid rounded-3 mb-3"
                  alt={project.name}
                  style={{ height: "450px", }}
                />
                <h5
                  style={{
                    cursor: "pointer",
                    color: "#903519",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  {project.name}
                </h5>
              </Link>
            ))}
          </div>

          {/* See More Button */}
          {filteredProjects.length > 6 && (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary"
                onClick={() => setShowAll((prev) => !prev)}
                style={{
                  backgroundColor: "#903519",
                  border: "none",
                  padding: "10px 20px",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                {showAll ? "See Less" : "See More"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
  );
};

export default OurBestProjects;
